import React, { useState, useEffect} from "react"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import Head from "../components/head"
import ContactLink from "../components/contactLink"

import basics from '../../ben-edit/basics'

export default () => {
  const toEmail = basics.email;

  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  const handleSubjectChange = e => setSubject(e.target.value);
  const handleBodyChange = e => setBody(e.target.value);

  const [mailTo, setMailTo] = useState(undefined);

  useEffect(() => {
    setMailTo(`mailto:${toEmail}?subject=${subject}&body=${body}`);
  }, [subject, body])

  return (
    <Layout>
      <Head title="Contact" />

      <p
        css={css`
          color: var(--text-light);
          font-size: 18px;
          line-height: 1.5;
          margin-bottom: 1rem;
        `}
      >
        {basics.contact_text}
      </p>

      <ContactLink
        service="Email"
        username={basics.email}
      />

      <ContactLink
        service="Twitter"
        username={basics.twitter}
      />

      <ContactLink
        service="Discord"
        username={basics.discord}
      />

      <form
        css={css`
          margin-top: 2rem;

          * {
            outline: none;
            appearance: none;
            border: none;
            background: transparent;
            box-sizing: border-box;
          }

          label {
            color: var(--text-light);
            margin-bottom: .5rem;
            display: block;
          }

          input, textarea, a {
            border: .2rem solid var(--text-lighter);
            border-radius: .5rem;
            width: 100%;
            margin-bottom: 1rem;
            padding: 1rem 1rem;
          }

          textarea {
            min-height: 12rem;
            resize: vertical;
            line-height: 1.5;
          }
        `}
      >

        <label htmlFor="subject">Subject</label>
        <input id="subject" type="text" value={subject} onChange={handleSubjectChange} />

        <label htmlFor="body">Body</label>
        <textarea value={body} onChange={handleBodyChange}></textarea>

        <a
          href={mailTo}
          css={css`
            text-decoration: none;
            display: block;
            margin-top: 1rem;
            text-align: center;
            font-weight: 500;
            cursor: pointer;
            :hover > span {
              transform: translateX(.3rem);
            }
          `}>
          Send
          <span
            css={css`
              margin-left: .4rem;
              font-weight: inherit;
              transition: transform .2s;
              display: inline-block;
            `}
          >
            &rarr;
          </span>
        </a>
      </form>
    </Layout>
  )
}
